/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                var $menu = $('#menu'),
                    $menulink = $('.menu-link'),
                    $menuclose = $('.menu-close'),
                    $overlay = $('#overlay'),
                    $menuTrigger = $('.menu-item-has-children');

                //open mobile menu
                $menulink.click(function(e) {
                    e.preventDefault();
                    $menulink.toggleClass('active');
                    $menu.toggleClass('active');
                    $overlay.toggleClass('is-visible');

                    $('#nav-menu').removeClass('sticky');

                    $(window).scrollTop(0);
                });
                //close mobile menu
                $menuclose.click(function(e) {
                    e.preventDefault();
                    $menulink.toggleClass('active');
                    $menu.toggleClass('active');
                    $overlay.toggleClass('is-visible');
                });

                //open submenu
                $menuTrigger.click(function(e) {
                    if ($menulink.hasClass("active")) {
                        var $this = $(this);
                        $this.toggleClass('active');
                        $this.find('.sub-menu').first().slideToggle();
                        $this.find('a').first().toggleClass('active');

                    }
                });

                //slider - slick carossel
                $(".slider").slick({
                    dots: true,
                    autoplay: true,
                    autoplaySpeed: 6000,
                    arrows: false
                });

                var stickyNavTop = $('.page-header').offset().top;
                var stickyNav = function() {
                    var scrollTop = $(window).scrollTop();
                    if (scrollTop > stickyNavTop) {
                     $('#nav-menu').addClass('sticky');
                        //         $('.site-wrapper').addClass('is-sticky');

                    } else {
                       $('#nav-menu').removeClass('sticky');
                        //         $('.site-wrapper').addClass('is-sticky');
                    }
                };
                if (!$menu.hasClass("active")) {
                    stickyNav();
                }
                $(window).scroll(function() {
                    if (!$menu.hasClass("active")) {
                        stickyNav();
                    }
                });




                /******************/
                /* Hero image fix for ie */
                /**********/
                // Object-fit fallback
                if ( ! Modernizr.objectfit ) {
          
                  $('.hero').each(function () {
                    var $container = $(this),
                        imgUrl = $container.find('img').prop('src');
                    if (imgUrl) {
                      $container
                        .css('backgroundImage', 'url(' + imgUrl + ')')
                        .addClass('compat-object-fit');
                    }  
                  });
                }


                /**
                 * Check a href for an anchor. If exists, and in document, scroll to it.
                 * If href argument ommited, assumes context (this) is HTML Element,
                 * which will be the case when invoked by jQuery after an event
                 */
                function scroll_if_anchor(href) {
                    href = typeof(href) === "string" ? href : $(this).attr("href");

                    // You could easily calculate this dynamically if you prefer
                    var fromTop = 90;

                    // If our Href points to a valid, non-empty anchor, and is on the same page (e.g. #foo)
                    // Legacy jQuery and IE7 may have issues: http://stackoverflow.com/q/1593174
                    if (href.indexOf("#") === 0) {
                        var $target = $(href);

                        // Older browser without pushState might flicker here, as they momentarily
                        // jump to the wrong position (IE < 10)
                        if ($target.length) {
                            $('html, body').animate({ scrollTop: $target.offset().top - fromTop });
                            if (history && "pushState" in history) {
                                history.pushState({}, document.title, window.location.pathname + href);
                                return false;
                            }
                        }
                    }
                }

                // When our page loads, check to see if it contains and anchor
                scroll_if_anchor(window.location.hash);


            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'request_a_quote': {
            init: function() {},
            finalize: function() {
                var contactAddress, contactUnitNumber, contactCity, contactPo, contactPostalCode;
                $('.contact-address > div > input').keyup(function() {
                    contactAddress = $(this).val();
                });
                $('.contact-unit-number > div > input').keyup(function() {
                    contactUnitNumber = $(this).val();
                });
                $('.contact-city > div > input').keyup(function() {
                    contactCity = $(this).val();
                });
                $('.contact-po > div > input').keyup(function() {
                    contactPo = $(this).val();
                });
                $('.contact-postal-code > div > input').keyup(function() {
                    contactPostalCode = $(this).val();
                });

                $('li.same-as-contact-address > div > ul > li > input').change(function() {
                    if ($(this).is(":checked")) {
                        $('.delivery-address > div > input').val(contactAddress);
                        $('.delivery-unit-number > div > input').val(contactUnitNumber);
                        $('.delivery-city > div > input').val(contactCity);
                        $('.delivery-po > div > input').val(contactPo);
                        $('.delivery-postal-code > div > input').val(contactPostalCode);
                    } else {
                        $('.delivery-address > div > input').val('');
                        $('.delivery-unit-number > div > input').val('');
                        $('.delivery-city > div > input').val('');
                        $('.delivery-po > div > input').val('');
                        $('.delivery-postal-code > div > input').val('');

                    }
                });

                $('li.same-as-delivery-address > div > ul > li > input').change(function() {
                    if ($(this).is(":checked")) {
                        $('.install-address > div > input').val($('.delivery-address > div > input').val());
                        $('.install-unit-number > div > input').val($('.delivery-unit-number > div > input').val());
                        $('.install-city > div > input').val($('.delivery-city > div > input').val());
                        $('.install-po > div > input').val($('.delivery-po > div > input').val());
                        $('.install-postal-code > div > input').val($('.delivery-postal-code > div > input').val());
                    } else {
                        $('.install-address > div > input').val('');
                        $('.install-unit-number > div > input').val('');
                        $('.install-city > div > input').val('');
                        $('.install-po > div > input').val('');
                        $('.install-postal-code > div > input').val('');

                    }
                });

                $('input[type=reset]').click(function() {
                    contactAddress = '';
                    contactUnitNumber = '';
                    contactCity = '';
                    contactPo = '';
                    contactPostalCode = '';
                });

            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.